import { AppContext } from "context/appContext";
import { DeviceEnum } from "models/enums";
import { useContext, useEffect, useState } from "react";

const useResize = () => {
    const { device: deviceFromContext } = useContext(AppContext);

    const [whichDevice, setWhichDevice] = useState(deviceFromContext);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 767 && whichDevice !== DeviceEnum.MOBILE) {
                setWhichDevice(DeviceEnum.MOBILE);
            }

            if (window.innerWidth > 767 &&  whichDevice === DeviceEnum.MOBILE) {
                setWhichDevice(DeviceEnum.DESKTOP);
            }
        }

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [whichDevice, deviceFromContext]);

    return { whichDevice };
};

export default useResize;
