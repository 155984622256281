import { DeviceEnum } from 'models/enums';
import { createContext } from 'react';

export type AppContextInterface = {
    device: string,
    settings: any,
    openFilterInMobile: any,
    openFilters: boolean,
};

const defaultContextValues: AppContextInterface = {
    device: DeviceEnum.DESKTOP,
    settings: {},
    openFilterInMobile: ()=> null,
    openFilters: false,
};
  
const AppContext = createContext<AppContextInterface>(defaultContextValues);

export { AppContext };
